














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Carousel extends Vue {
  @Prop(Array) readonly images!: Array<string>;
}
